
import { defineComponent, reactive, toRefs } from "vue";
import { NavBar, Search, Empty, List } from "vant";
import { getModelList } from "@/api/service";
import router from "@/router";
export default defineComponent({
  components: {
    [NavBar.name]: NavBar,
    [Search.name]: Search,
    [Empty.name]: Empty,
    [List.name]: List,
  },
  setup() {
    const state = reactive({
      // 案例列表
      caseList: [],
      searchInput: "",
    });

    // 获取模型列表
    const getModel = async () => {
      let condition = {
        rows: 10,
        page: 1,
        name: state.searchInput,
      };
      const res = await getModelList(condition);
      if (
        res.data.code == 200 &&
        res.data.result.list &&
        res.data.result.list.length > 0
      ) {
        state.caseList = res.data.result.list;
      } else {
        state.caseList = [];
      }
    };

    // 返回
    const onClickLeft = () => {
      state.caseList = [];
      state.searchInput = "";
      router.go(-1);
    };
    // 搜索
    const onSearch = () => {
      getModel();
      return;
    };

    // 清空输入框
    const onClear = () => {
      state.searchInput = "";
      state.caseList = [];
    };

    // 前往案例页
    const toCase = (val: string) => {
      window.open(val);
    };
    return {
      ...toRefs(state),
      onClickLeft,
      onSearch,
      onClear,
      toCase,
    };
  },
});
