<template>
  <div class="search">
    <van-nav-bar
      safe-area-inset-top
      fixed
      placeholder
      title="查找案例"
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <van-search
      v-model="searchInput"
      show-action
      clearable
      placeholder="请输入案例关键词"
      @search="onSearch"
      @clear="onClear"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div class="case_content">
      <van-list
        v-show="caseList.length > 0"
        :finished="true"
        finished-text="没有更多了"
      >
        <div
          class="case_content_item"
          v-for="(item, index) in caseList"
          :key="index"
          @click="toCase(item.link)"
        >
          <img :src="'//manage.513dvr.com' + item.thumb" />
          <p
            class="display_flex align-items_center justify-content_flex-justify"
          >
            <span>{{ item.title }}</span>
            <span>进入<van-icon name="arrow" /></span>
          </p>
        </div>
      </van-list>
    </div>
    <van-empty
      v-show="!caseList.length > 0"
      image="search"
      description="您还没有搜索"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { NavBar, Search, Empty, List } from "vant";
import { getModelList } from "@/api/service";
import router from "@/router";
export default defineComponent({
  components: {
    [NavBar.name]: NavBar,
    [Search.name]: Search,
    [Empty.name]: Empty,
    [List.name]: List,
  },
  setup() {
    const state = reactive({
      // 案例列表
      caseList: [],
      searchInput: "",
    });

    // 获取模型列表
    const getModel = async () => {
      let condition = {
        rows: 10,
        page: 1,
        name: state.searchInput,
      };
      const res = await getModelList(condition);
      if (
        res.data.code == 200 &&
        res.data.result.list &&
        res.data.result.list.length > 0
      ) {
        state.caseList = res.data.result.list;
      } else {
        state.caseList = [];
      }
    };

    // 返回
    const onClickLeft = () => {
      state.caseList = [];
      state.searchInput = "";
      router.go(-1);
    };
    // 搜索
    const onSearch = () => {
      getModel();
      return;
    };

    // 清空输入框
    const onClear = () => {
      state.searchInput = "";
      state.caseList = [];
    };

    // 前往案例页
    const toCase = (val: string) => {
      window.open(val);
    };
    return {
      ...toRefs(state),
      onClickLeft,
      onSearch,
      onClear,
      toCase,
    };
  },
});
</script>
<style lang="scss" scoped>
.van-search {
  position: fixed;
  width: 100%;
  top: 45px;
}
.case_content {
  margin-top: 65px;
  padding: 0 10px;

  &_item {
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    padding: 10px;
    margin-bottom: 10px;

    > img {
      width: 100%;
      height: 190px;
    }

    > p {
      margin: 20px 0 10px 0;

      > span:first-of-type {
        font-size: 15px;
        font-weight: bold;
        color: #333333;
        display: inherit;
        width: calc(100% - 80px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      > span:last-of-type {
        font-size: 13px;
        font-weight: 400;
        color: #999999;
        display: inherit;
        width: 50px;
        justify-content: flex-end;

        > i {
          margin-top: 2px;
        }
      }
    }
  }
}
</style>
